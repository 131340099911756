$(document).ready(function () {
  $("#end-time-wrapper").hide();
  checkWording();
  updateSelectedOptions();

  $("#job_job_type").on("change", function () {
    checkWording();
    enableModal();
    updatePricingConfirmation();
  });

  $("#job_all_day").on("change", function () {
    updateTimes();
  });

  $("#job_office_id").on("change", function () {
    updateSelectedOptions();
  });

  $("#job_price").on("keyup", function () {
    updatePricingConfirmation();
  });


  $("#new-job-form").on(
    "cocoon:after-insert",
    function (e, insertedItem, originalEvent) {
      updatePricingConfirmation();
      // checkWording();
    }
  );

  $("#new-job-form").on(
    "cocoon:after-remove",
    function (e, insertedItem, originalEvent) {
      updatePricingConfirmation();
    }
  );
});

function checkWording() {
  if ($("#new-job-form #job_job_type").val() == "open_house") {
    $("#new-job-form #job_start_time").siblings("label").text("Start Time");
    $("#new-job-form #job_end_time").siblings("label").text("End Time");
    $("#new-job-form #job_price").attr("placeholder", "Price");
    $("#new-job-form .lead-info").hide();
    $("#new-job-form #p-wrapper").show();
    $("#new-job-form #p-wrapper input").val("");
    $("#new-job-form #job_date").closest(".form-group").show();
  } else if ($("#new-job-form #job_job_type").val() == "lead") {
    //if lead type then hide the date from the screen
    //also hide the address fields. Hide the start and end time entries and the
    //worker_schedule checkbox and label
    $(
      "#new-job-form input[id*='address_attributes_state'],#new-job-form input[id*='address_attributes_zip']"
    ).removeAttr("required");
    $("#new-job-form .lead-info").show();
    $("#new-job-form #between_times").hide();
    $("#new-job-form #job_date").closest(".form-group").hide();
    $("#new-job-form #job_worker_schedule").closest(".form-check").hide();
    $("#new-job-form #job_start_time").val("00:00:00");
    $("#new-job-form #job_end_time").val("23:59:00");
    $("#new-job-form #job_price").attr(
      "placeholder",
      "This can be a renter or buyer's budget/price range, estimate value for a listing lead, or anything else that helps describe the value."
    );
    $("#new-job-form input[id$='address_attributes_address']").attr(
      "placeholder",
      "Address - This can be full address, a neighborhood or an area."
    );
    $("#new-job-form #p-wrapper").hide();
    $("#new-job-form #p-wrapper input").val("showing");
    $("#new-job-form #job_price_description").removeAttr("required");
  } else {
    $("#new-job-form .lead-info").hide();
    $("#new-job-form #p-wrapper").show();
    $("#new-job-form #p-wrapper input").val("");
    $("#new-job-form #job_start_time")
      .siblings("label")
      .text("Available to Start");
    $("#new-job-form #job_end_time").siblings("label").text("Available Until");
    $("#new-job-form #job_price").attr("placeholder", "Price per address");
    $("#new-job-form #job_date").closest(".form-group").show();
  }
  if ($("#new-job-form #job_job_type").val() != "lead") {
    $("#new-job-form input[id$='address_attributes_address']").attr(
      "placeholder",
      "Address 1"
    );
    $(
      "#new-job-form input[id*='address_attributes_state'],#new-job-form input[id*='address_attributes_zip']"
    ).attr("required", "required");
    $("#job_price_description").removeAttr("required");
  }
  if ($("#new-job-form #job_job_type").val() == "miscellaneous") {
    $("#new-job-form #all_day_wrapper").show();
  } else {
    $("#new-job-form #all_day_wrapper").hide();
    //$("#job_start_time, job_end_time").val("")
    $("#new-job-form #job_all_day").prop("checked", false);
  }
  if ($("#new-job-form #job_job_type").val() == "showing") {
    $("#job_buyer_exclusive_agreement").attr("required", "required");
  } else {
    $("#job_buyer_exclusive_agreement").removeAttr("required");
  }
  updateTimes();
}

function enableModal() {
  // default is modal-save is shown and reg-save is hidden
  // if showing type is anything buy 0 then show reg-save and hide modal-save
  if (document.getElementById("modal-save") != null) {
    if ($("#new-job-form #job_job_type").val() != "showing") {
      $("#new-job-form #reg-save").show();
      $("#new-job-form #modal-save").hide();
    } else {
      $("#new-job-form #reg-save").hide();
      $("#new-job-form #modal-save").show();
    }
  }
}

function updateTimes() {
  if ($("#new-job-form #job_job_type").val() == "lead") {
    return;
  } else {
    $("#new-job-form #job_all_day").is(":checked")
      ? $("#new-job-form #between_times").hide()
      : $("#new-job-form #between_times").show();
  }
}

function updateSelectedOptions() {
  var html = "";
  $("#job_office_id")
    .find(":selected")
    .each(function () {
      html += `<span class='badge badge-primary badge-sm mr-1 mb-1'>${$(
        this
      ).text()}</span>`;
    });
  $("#optionsSelected").html(html);
}

function updatePricingConfirmation() {
  //if job type is lead, #job_price is 0 or blank, or if #job_price is not a number, hide the price confirmation
  if ($("#job_job_type").val() == "lead" || $("#job_price").val() == "" || $("#job_price").val() == "0" || isNaN($("#job_price").val())) {
    $("#pay-confirm-wrapper").hide();
    $("#job_payment_confirm").attr("required", false);
  } else (
    $("#pay-confirm-wrapper").show(),
    $("#job_payment_confirm").attr("required", true)
  )
  if ($("#job_price").val() != "") {
    // use the different constants set to calc the total price
    let basePrice = parseInt($("#job_price").val() * $("#properties .nested-fields").length) * 100;
    let baseWithFees = basePrice + PLATFORM_FEE + STRIPE_FLAT_FEE
    let totalPrice = ((baseWithFees / (1 - STRIPE_PERCENTAGE_FEE)) / 100).toFixed(2);
    $("#price-confirm").text(totalPrice);
    $("#price-explain").attr('data-original-title', `$${(basePrice / 100).toFixed(2)} Job Cost<br>$${(STRIPE_FLAT_FEE / 100).toFixed(2)} Stripe Fee<br>$${(PLATFORM_FEE / 100).toFixed(2)} Platform Fee<br>${(STRIPE_PERCENTAGE_FEE * 100).toFixed(1)}% Stripe Processing Fee`);
  }
}
